.App {
  text-align: center; /* Center-aligns text within the app */
  font-family: 'Courier New', sans-serif; /* Sets the font family */
  background-image: url('https://dailypolygoncommunity.vercel.app/static/media/background1.b0fdbbea6a6df56746a4.png'); /* Path to background image */
  background-size: cover; /* Ensures the background image covers the entire element */
  background-position: center; /* Centers the background image */
  padding: 20px; /* Adds padding around the content */
  min-height: 100vh; /* Ensures the background covers the entire viewport */
}

.container {
  max-width: 400px; /* Limits the container's width for better readability */
  margin: auto; /* Centers the container */
  padding: 20px; /* Adds padding inside the container */
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent dark background */
  border-radius: 10px; /* Rounds the corners of the container */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow effect */
  color: white; /* Sets the text color to white for contrast */
}

h1 {
  margin-bottom: 20px; /* Adds space below the heading */
  font-family: 'Courier New', Courier, monospace; /* Sets font to Courier New for a distinctive look */
  color: rgb(199, 176, 176); /* Gray color for the main heading */
}

h2 {
  font-size: 1em; /* Sets the font size for subheadings */
  color: #6f6868; /* Soft gray color for subheadings */
}

.button-group {
  display: flex; /* Uses flexbox for button alignment */
  justify-content: space-between; /* Distributes buttons with space in between */
  margin: 10px 0; /* Adds vertical margin around the button group */
}

.button-group button {
  margin-right: 10px; /* Adds spacing between buttons */
}

.button-group button:last-child {
  margin-right: 0; /* Removes extra margin for the last button */
}

.input-field {
  width: 95%; /* Sets the width of input fields */
  padding: 10px; /* Adds padding inside input fields */
  border: 1px solid #312e2e; /* Dark border for contrast */
  border-radius: 4px; /* Rounds the corners of the input fields */
  margin: 10px 0; /* Adds vertical margin around input fields */
  font-size: 15px; /* Sets font size for input text */
}

.investment-buttons {
  display: flex; /* Uses flexbox for button alignment */
  justify-content: center; /* Centers the investment buttons */
  gap: 5px; /* Spacing between Invest and Withdraw buttons */
  margin: 10px 0; /* Adds vertical margin around the investment buttons */
}

button {
  background-color: #6c63ff; /* Primary button color */
  color: rgb(200, 188, 188); /* Sets text color for buttons */
  border: none; /* Removes default border */
  padding: 12px 20px; /* Adds padding inside buttons */
  border-radius: 4px; /* Rounds the corners of the buttons */
  cursor: pointer; /* Changes cursor to pointer on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  flex: 1; /* Makes buttons expand to fill available space */
}

button:hover {
  background-color: #24c615; /* Green color for hover effect */
}

.connect-button {
  background-color: #007bff; /* Unique color for the Connect Wallet button */
}

/* New styles for messages */
.error-message {
  color: red; /* Text color for error messages */
  background-color: rgba(206, 235, 58, 0.1); /* Light red background for visibility */
  border: 1px solid red; /* Red border to highlight the error */
  padding: 10px; /* Adds padding around the message */
  border-radius: 5px; /* Rounds the corners */
  margin: 10px 0; /* Adds space above and below the message */
  font-size: 14px; /* Sets the font size for the message */
}

.success-message {
  color: green; /* Text color for success messages */
  background-color: rgba(0, 255, 0, 0.1); /* Light green background for visibility */
  border: 1px solid green; /* Green border for success indication */
  padding: 10px; /* Adds padding around the message */
  border-radius: 5px; /* Rounds the corners */
  margin: 10px 0; /* Adds space above and below the message */
  font-size: 14px; /* Sets the font size for the message */
}

.app-title {
  font-size: 1.8em; /* Adjusts the size of the title */
  color: #7e777b; /* Sets the title color */
  margin-bottom: 10px; /* Adds space below the title */
}

.app-subtitle {
  font-size: 0.8em; /* Adjusts the size of the subtitle */
  color: #b7b1b7; /* Sets the subtitle color */
  font-style: italic; /* Makes the subtitle italic */
  margin-bottom: 0px; /* Adds space below the subtitle */
}

.investment-calculator {
  color: rgb(183, 169, 169); /* Sets text color to white */
  margin: 20px 0; /* Adds vertical margin */
}

.investment-calculator h3 {
  font-family: 'Courier New', sans-serif; /* Sets font for calculator title */
}

.investment-calculator input {
  width: 95%; /* Width of input */
  padding: 10px; /* Padding inside input */
  border: 1px solid #4b4646; /* Border for input */
  border-radius: 4px; /* Rounds the corners for input */
  margin: 10px 0; /* Vertical margin for input */
}

.investment-calculator button {
  background-color: #6c63ff; /* Background color for calculator button */
  color: rgb(180, 175, 175); /* Text color for button */
  border: none; /* No border */
  padding: 10px; /* Padding inside button */
  border-radius: 4px; /* Rounds the corners for button */
  cursor: pointer; /* Changes cursor to pointer on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover */
}

.investment-calculator button:hover {
  background-color: #24c615; /* Color for button on hover */
}
